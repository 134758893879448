.registerDemoModal {
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(3px);
}

.leftContainer {
  min-width: 520px;
  max-width: 520px;
}

.rightContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.registerDemoForm {
  width: 520px;
  height: 660px;
  background-color: white;
  padding: 32px;
  border-radius: 16px;
  box-shadow: 0px 16px 32px 0px #16223329;
  box-shadow: 0px 4px 12px -4px #1622331f;
  opacity: 0;
  translate: 0px -20px;
  transition: height 0.2s ease-in-out;
  overflow: hidden;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-duration: 0.4s;
  animation-delay: 1s;
}
.fadeInTop {
  animation-name: fadeInTop;
}
@keyframes fadeInTop {
  from {
    opacity: 0;
    translate: 0px 20px;
  }
  to {
    opacity: 1;
    translate: 0px 0px;
  }
}
