.capsule {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  padding: 0px 12px;
  border: 1px solid var(--neutral500);
  border-radius: 12px;
  cursor: pointer;
  position: relative;
}
.capsule:hover {
  text-decoration: underline;
  background-color: var(--neutral400);
}
