.transactionOptionButton {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 32px;
  border-radius: 4px;
  padding: 0px 8px;
  border: 1px solid var(--neutral500);
}
.transactionOptionButton:hover {
  background-color: var(--neutral400);
}
