.jackTransferInfoModal {
  min-width: 516px;
}

.content {
  width: 100%;
  padding: 20px 20px 32px 20px;
}

.articleLink {
  cursor: pointer;
  text-decoration: underline;
  font-family: GothamMedium;
}
.articleLink:hover,
.articleLink:active {
  color: var(--neutral800);
}

.video {
  overflow: hidden;
  border-radius: 8px;
}
