.container {
  display: flex;
  justify-content: center;
}

.content {
  width: 438px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.summary {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid var(--neutral500);
  border-radius: 4px;
  margin-top: 32px;
}

.summaryTitle {
  display: flex;
  align-items: center;
  gap: 8px;
}

.summaryIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: var(--neutral900);
}

.summaryItem {
  display: flex;
  justify-content: space-between;
}

.recipient {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.divider {
  width: 100%;
  border-top: 1px solid var(--neutral500);
}
