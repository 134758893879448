.companyItem {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 60px;
  cursor: pointer;
  padding: 0px 8px;
  border-radius: 8px;
}

.companyItem:hover {
  background-color: var(--neutral400);
}
