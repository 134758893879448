.sideBannerContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 64px 32px 32px 32px;
  translate: -100% 0px;
  background: linear-gradient(
    100.71deg,
    rgba(0, 0, 0, 0.85) 5.91%,
    rgba(53, 51, 51, 0.85) 101.05%
  );
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-duration: 0.4s;
  animation-delay: 1s;
}
.slideInRight {
  animation-name: slideInRight;
}
@keyframes slideInRight {
  from {
    translate: -100% 0px;
  }
  to {
    translate: 0px 0px;
  }
}
.logoContainer {
  display: flex;
  align-items: center;
  gap: 16px;
}
.demoTag {
  padding: 2px 8px;
  border-radius: 4px;
  background-color: white;
}
