.footerTagsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 16px;
}
.copyright {
  display: flex;
  align-items: center;
  gap: 2px;
}
