.selectedCompany {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--neutral500);
  border-radius: 8px;
  margin-top: 32px;
  padding-right: 8px;
}

.companyItem {
  width: fit-content;
  cursor: default;
}
.companyItem:hover {
  background-color: white;
}
