.dropdownSelection {
  position: absolute;
  z-index: 100;
  top: 106px;
  width: 100%;
  background-color: white;
  border: 1px solid var(--neutral400);
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0px 8px 16px -4px #16223314;
}
