.button-outer {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 56px;
  background: var(
    --Gradient-2,
    linear-gradient(101deg, #000 5.91%, #353333 101.05%)
  );
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08),
    0px 16px 24px 0px rgba(22, 34, 51, 0.08);
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 9;
  cursor: pointer;
}
.button-outer:hover {
  /* background: red; */
}

.modal-outer {
  display: flex;
  width: 400px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 20px;
  background: var(
    --Gradient-2,
    linear-gradient(101deg, #000 5.91%, #353333 101.05%)
  );
  height: 82vh;

  /* Shadow/Normal */
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08),
    0px 16px 24px 0px rgba(22, 34, 51, 0.08);
}
.modal-header-outer {
  z-index: 9999999;
}
.modal-header {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  background-color: transparent;
  z-index: 999;
}
.modal-body-container {
  overflow-y: scroll;
  scrollbar-width: thin;
  border: 1px solid var(--Neutral-400, #f1f1f1);
  border-radius: 12px;
  background-color: transparent;
  position: relative;
  z-index: 99;
}
.modal-body {
  padding: 16px;
  background: var(--Primary-White, #fff);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
}
.product-card {
  border-radius: 12px;
  border: 1px solid var(--Neutral-500, #e6e6e8);
  background: var(--Neutral-200, #fcfcfc);
  width: 100%;
}
.card-header {
  border-bottom: 1px solid var(--Neutral-500, #e6e6e8);
  background: var(--Primary-White, #fff);
  display: flex;
  padding: 10px 12px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  justify-content: space-between;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.card-header-left {
  display: flex;
  align-items: center;
  gap: 12px;
}
.card-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--Neutral-500, #e6e6e8);
  background: var(--Neutral-400, #f1f1f1);
  height: 32px;
  width: 32px;
}
.btn-show-me {
  border-radius: 32px;
  border: 1px solid var(--Neutral-500, #e6e6e8);
  background: var(--Neutral-100, #fff);
  display: flex;
  height: 32px;
  padding-right: 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.btn-show-me:hover {
  background-color: #f1f1f1;
}
.card-body {
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: flex-start;
}
