.fullScreenLoading {
  position: relative;
}
.fullScreenLoading > div {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 16px;
  bottom: -63px;
}

.loader {
  rotate: 0deg;
  animation-name: rotate;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

@keyframes rotate {
  from {
    rotate: 0deg;
  }

  to {
    rotate: 360deg;
  }
}
