.carouselContainer {
  width: 100%;
  height: 357px;
  position: relative;
  padding: 24px;
  border-radius: 20px;
  background-color: var(--neutral900);
  overflow: hidden;
}
.lightingContainer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.contentContainer {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.carouselTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.carouselNavContainer {
  display: flex;
  align-items: center;
  gap: 16px;
}
.carouselButtonsContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}
.carouselDotContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}
.carouselDot {
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 4px;
  transition: all 0.2s linear;
}
.slidesContainer {
  width: 100%;
  height: calc(100% - 54px);
  display: flex;
  gap: 24px;
  margin-top: 24px;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
  /* overflow: hidden; */
}
.slide {
  width: 100%;
  min-width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: white;
}
.play-button {
  appearance: none;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  font: inherit;
  color: inherit;
  cursor: pointer;
  outline: none;
  position: absolute;
  top: calc(50%);
  left: 50%;
  transform: translate(-50%, calc(-50%));
  border-radius: 100px;
  background: var(--Neutral-900, #343434);
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
}
.play-button:hover {
  background-color: #4a4a4a;
}
.slide-caption {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 12px;
  align-items: flex-start;
  position: absolute;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  bottom: 0px;
  background: rgba(52, 52, 52, 0.5);
  backdrop-filter: blur(8px);
}
.last-slide {
  width: 100%;
  min-width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: transparent;
}
.last-slide-text-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 10px; /* Gap between grid items */
}
.last-slide-text {
  display: flex;
  gap: 8px;
}
.swipeLeftOnZeroIndex {
  animation-name: swipeLeftOnZeroIndex;
}
.swipeLeftOnFirstIndex {
  animation-name: swipeLeftOnFirstIndex;
}
.swipeRightOnSecondIndex {
  animation-name: swipeRightOnSecondIndex;
}
.swipeRightOnFirstIndex {
  animation-name: swipeRightOnFirstIndex;
}
@keyframes swipeLeftOnZeroIndex {
  0% {
    translate: 0px 0px;
  }
  50% {
    translate: calc(-100% - 24px - 28px) 0px;
  }
  100% {
    translate: calc(-100% - 24px) 0px;
  }
}
@keyframes swipeLeftOnFirstIndex {
  0% {
    translate: calc(-100% - 24px) 0px;
  }
  50% {
    translate: calc(-200% - 48px - 52px) 0px;
  }
  100% {
    translate: calc(-200% - 48px) 0px;
  }
}
@keyframes swipeRightOnSecondIndex {
  0% {
    translate: calc(-200% - 48px) 0px;
  }
  50% {
    translate: calc(-100% - 24px + 52px) 0px;
  }
  100% {
    translate: calc(-100% - 24px) 0px;
  }
}
@keyframes swipeRightOnFirstIndex {
  0% {
    translate: calc(-100% - 24px) 0px;
  }
  50% {
    translate: calc(0px + 28px) 0px;
  }
  100% {
    translate: 0px 0px;
  }
}
