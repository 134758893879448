.spinningLoading {
  width: 20px;
  height: 20px;
  animation-name: rotating;
  animation-duration: 1500ms;
  animation-timing-function: linear;
  animation-fill-mode: backwards;
  animation-iteration-count: infinite;
}

@keyframes rotating {
  from {
    rotate: 0deg;
  }

  to {
    rotate: 360deg;
  }
}
