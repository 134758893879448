.confirmSubmit {
  width: 438px;
  display: flex;
  flex-direction: column;
}

.label {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 24px;
}

.detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.divider {
  width: 100%;
  border-top: 1px solid var(--neutral500);
  margin-top: 24px;
}
